import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import React from 'react'

import { useLightboxStore } from '../../stores/lightbox'
import PhotosCta from '../../svg/photos-cta.svg'
import ArrowDropdown from '../../svg/arrow-dropdown.svg'

const Button = ({
  className,
  to,
  type,
  children,
  colour,
  rel,
  target,
  full,
  showGalleryIcon,
  images,
  showArrowIcon,
  setShowMore,
  psuedo,
  isHeading,
}) => {

  
  const { showGallery, setShowGallery, setImages } = useLightboxStore()
  const internal = /^\/(?!\/)/.test(to)  
  let buttonColour = ''

  function buttonAction() {
    if (images) {
      setShowGallery(!showGallery)
      setImages(images)
    }
    if (showArrowIcon) {
      setShowMore(true)
    }
  }

  switch (colour) {
    case 'blue':
      buttonColour =
        'bg-blue-500 border border-solid bg-blue-500 bg-opacity-80 hover:bg-opacity-100 text-white outline-blue-500'
      break
    case 'ghostBlue':
      buttonColour = 'border border-solid border-blue-500 text-blue-500'
      break
    case 'ghostBlueThick':
    case 'blueOutline':
      buttonColour =
        'border-2 border-solid border-blue-500 hover:bg-blue-100 text-blue-500'
      break
    case 'white':
      buttonColour =
        'transition bg-white bg-opacity-80 hover:bg-opacity-100 text-blue-500'
      break
    default:
    case 'green':
      buttonColour =
      'bg-aqua-500 border border-solid border-aqua-500 hover:border-opacity-80 hover:bg-opacity-80 text-white focus:outline-aqua-500'
      break
  }

  const ButtonClasses = `button ${
    full ? 'button-full' : ''
  } relative inline-flex items-center rounded-4 text-sm leading-none font-bold mx-1 ${buttonColour} ${className}`

  let relValue
  if (rel && target) {
    relValue = 'nofollow noopener noreferrer'
  } else if (rel) {
    relValue = 'nofollow'
  }

  if (to && internal) {    
    let link = to

    if (link) {
      if (!to.endsWith('/')) {
        link = `${to}/`
      }
    }
    return (
      <Link
        to={link}
        className={ButtonClasses}
        data-text={children}
        rel={relValue}
        target={target ? '_blank' : ''}
      >
        <span>{children}</span>
      </Link>
    )
  }

  if (to && !internal) {
    return (
      <a
        href={to}
        className={ButtonClasses}
        data-text={children}
        rel={relValue}
        target={target ? '_blank' : ''}
      >
        <span>{children}</span>
      </a>
    )
  }

  if (psuedo || to === null) {
    return (
      <span
        className={ButtonClasses}
        data-text={children}
        rel={relValue}
        target={target ? '_blank' : ''}
      >
        <span>{children}</span>
      </span>
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={`${ButtonClasses} ${
        showGalleryIcon || showArrowIcon ? 'button-icon' : ''
      }`}
      type={type}
      data-text={children}
      onClick={() => buttonAction()}
    >
      <span>{children}</span>
      {showGalleryIcon && (
        <PhotosCta className="absolute translate-y-0 right-4 tranlate-x-0" />
      )}
      {showArrowIcon && (
        <ArrowDropdown
          className="absolute -ml-4 right-4"
          style={{ transform: `translate3d(0, 0, 0) rotate(-90deg)` }}
        />
      )}
    </button>
  )
}

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  colour: PropTypes.string,
  rel: PropTypes.bool,
  target: PropTypes.bool,
  full: PropTypes.bool,
  showGalleryIcon: PropTypes.bool,
  images: PropTypes.array,
  showArrowIcon: PropTypes.bool,
  setShowMore: PropTypes.func,
  psuedo: PropTypes.bool,
}

Button.defaultProps = {
  to: '',
  children: '',
  type: 'button',
  className: '',
  colour: 'text-white bg-aqua-500',
  rel: false,
  target: false,
  full: false,
  showGalleryIcon: false,
  images: null,
  showArrowIcon: false,
  setShowMore: null,
  psuedo: false,
}

export default Button
