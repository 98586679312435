import React from 'react'
import PropTypes from 'prop-types'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

const Button = ({ paginate, disabled, prev, next }) => {
  const classes = prev ? 'left-4' : 'right-4'
  const direction = prev ? -1 : 1

  return (
    <button
      type="button"
      className={`absolute top-50 ${classes} h-12 w-12 rounded-full bg-blue-500 text-white flex items-center justify-center z-50`}
      onClick={() => paginate(direction)}
      disabled={disabled}
    >
      {prev && <FiChevronLeft className="-ml-1 w-8 h-8" />}
      {next && <FiChevronRight className="-mr-1 w-8 h-8" />}
    </button>
  )
}

export default Button

Button.propTypes = {
  paginate: PropTypes.func,
  prev: PropTypes.bool,
  next: PropTypes.bool,
}
